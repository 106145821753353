import './markdown.css';

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

type MarkdownDisplayComponentProps = {
  data: string;
};

export function MarkdownDisplayComponent({
  data,
}: MarkdownDisplayComponentProps): JSX.Element {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <>
      <ReactMarkdown
        className="mde-preview-content-user"
        remarkPlugins={[gfm]}
        // eslint-disable-next-line react/no-children-prop
        children={value}
      />
    </>
  );
}
